import React from "react";
import { Routes } from "../../routes/routes";
import { ThemeProvider, createTheme } from "@mui/material";

const darkTheme = createTheme ({
   /* palette: {
      mode: "dark"
    },
    */
  });

const App: React.FC = () => {
  return <ThemeProvider theme={darkTheme}>
      <Routes />
    </ThemeProvider>;
};

export { App };
