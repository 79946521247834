import { makeAutoObservable } from "mobx";
import { RegisterVarroaTestRequest } from "../dto/request/registervarroatest-request";
import { VarroaTestService } from "../services/varroatest.service";

export class VarroaTestStore {
  private submittedSuccessful = false;
  private submittedFailed = false;

  constructor(private readonly varroaTestService: VarroaTestService) {
    makeAutoObservable(this);
    //this.authenticated = !!this.getAccessToken();
    //this.authenticated = true;
  }

  setSubmittedSuccessful(val:boolean) {
    this.submittedSuccessful = val;
  }

  setSubmittedFailed(val:boolean) {
    this.submittedFailed = val;
  }


  async registerVarroaTest(registerVarroaTestRequest: RegisterVarroaTestRequest) {
    console.log("test");
    try {
      const tokenPayloadDto = await this.varroaTestService.registerVarroaTest(registerVarroaTestRequest);
      this.setSubmittedSuccessful(true);
      this.setSubmittedFailed(false);
      //localStorage.setItem("access_token", tokenPayloadDto.access_token);
      //this.setAuthenticated(true);
    } catch (err) {
      this.setSubmittedFailed(true);
      //this.setAuthenticated(false);
    }
  }

  isSubmittedFailed() {
    return this.submittedFailed;
  }


  isSubmittedSuccessful() {
    return this.submittedSuccessful;
  }

  /*getAccessToken() {
    return localStorage.getItem("access_token");
  }

  isAuthenticated() {
    return this.authenticated;
  }
  */
}
