import { makeAutoObservable } from "mobx";
import { LoginRequest } from "../dto/request/login-request.dto";
import { AuthService } from "../services/auth.service";
import { SignupRequest } from "../dto/request/signup-request.dto";

export class AuthStore {
  private authenticated = false;

  constructor(private readonly authService: AuthService) {
    makeAutoObservable(this);
    //this.authenticated = !!this.getAccessToken();
    this.authenticated = true;
  }

  async login(loginRequest: LoginRequest) {
    try {
      const tokenPayloadDto = await this.authService.login(loginRequest);
      localStorage.setItem("access_token", tokenPayloadDto.access_token);
      this.setAuthenticated(true);
    } catch (err) {
      this.setAuthenticated(false);
    }
  }

  async signup(signupRequest: SignupRequest) {
    try {
      const tokenPayloadDto = await this.authService.signup(signupRequest);
      localStorage.setItem("access_token", tokenPayloadDto.access_token);
      this.setAuthenticated(true);
    } catch (err) {
      this.setAuthenticated(false);
    }
  }

  private setAuthenticated(authenticated: boolean) {
    this.authenticated = authenticated;
  }

  getAccessToken() {
    return localStorage.getItem("access_token");
  }

  isAuthenticated() {
    return this.authenticated;
  }
}
