import React from "react";
import { AuthService } from "./services/auth.service";
import { VarroaTestService } from "./services/varroatest.service";
import { AuthStore } from "./stores/auth.store";
import { VarroaTestStore } from "./stores/varroatest.store";

interface IStoreContext {
  authStore: AuthStore;
  varroatestStore: VarroaTestStore;
}

const authService = new AuthService();
const authStore = new AuthStore(authService);

const varroatestService = new VarroaTestService();
const varroatestStore = new VarroaTestStore(varroatestService);

export const StoreContext = React.createContext<IStoreContext>({
  authStore,
  varroatestStore,
});
