import React, { useContext, useState } from "react";
import { observer } from "mobx-react-lite";
import { TextField, Button, Link as MuiLink } from "@mui/material";

import { StoreContext } from "../../store.context";
import { Link } from "react-router-dom";

const SignupFormView: React.FC = () => {
  const { authStore } = useContext(StoreContext);

  const [email, setEmail] = useState("");
  const [emailErrored, setEmailErrored] = useState(false);
  const [password, setPassword] = useState("");
  const [passwordErrored, setPasswordErrored] = useState(false);

  const handleSignup = () => {
    var Error = false;
    if(!email) {
      setEmailErrored(true);
      Error=true;
    }
    else {
      setEmailErrored(false);
    }
    if(!password) {
      setPasswordErrored(true);
      Error=true;
    }
    else {
      setPasswordErrored(false);
    }
    if(Error) {
    }
    else {
      authStore.signup({ email, password });
    }
      
  }; 

  return (
      <div className="flex justify-center items-center flex-col h-screen gap-8">
        <h1 className="text-6xl">Registrierung</h1>
        <form>
        <div className="flex flex-col gap-2">
          
        <TextField
          className="w-80"
          label="Email"
          type="email"
          value={email}
          helperText={emailErrored && "Ungültige Mailadresse."}
          required
          error={emailErrored}
          onChange={(event) => setEmail(event.target.value)}
        />
        <TextField
          className="w-80"
          label="Passwort"
          type="password"
          value={password}
          helperText={passwordErrored && "Ungültiges Passwort."}
          required
          error={passwordErrored}
          onChange={(event) => setPassword(event.target.value)}
        />
        <MuiLink component={Link} to="/login">Login</MuiLink>
        <Button onClick={() => handleSignup()} variant="contained" className="p-100">
          <span className="p-1">Registrieren</span>
        </Button>
       
        </div>
        </form>
      </div>
  );
};

const SignupForm = observer(SignupFormView);
export { SignupForm };
