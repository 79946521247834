import React, { useContext, useState } from "react";
import { observer } from "mobx-react-lite";
import { Dialog, TextField, Button, Slider, InputLabel, Select, MenuItem, Box, Link as MuiLink, Typography } from "@mui/material";

import { StoreContext } from "../../store.context";

const RegisterVarroaTestView: React.FC = () => {
  const { authStore, varroatestStore } = useContext(StoreContext);
  const submittedFailed = varroatestStore.isSubmittedFailed();
  const submittedSuccessful = varroatestStore.isSubmittedSuccessful();

  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [email, setEmail] = useState("");
  const [phonenr, setPhonenr] = useState("");
  const [street, setStreet] = useState("");
  const [postcode, setPostcode] = useState("");
  const [city, setCity] = useState("");
  const [amounthives, setAmountHives] = useState(1);
  const [tsknr, setTsknr] = useState("");

  var ErrorReport = "";

    if (submittedFailed) {
      ErrorReport = "Übertragung fehlgeschlagen!";
    } 
    if (submittedSuccessful){
      ErrorReport = "Übertragung erfolgreich! Sie erhalten in den nächsten Minuten eine EMail mit einer Bestätigung.";
    }

  return (
   

<div>

    <div className="flex justify-center items-center flex-col h-screen gap-8">
    <Box
      component="form"
      autoComplete="true"
    >

<h1 className="text-4xl">Ruhrstadt-Imker</h1>
    <h2 className="text-2xl">Monitoring Proben 2023</h2>
  
    <div className="flex flex-col gap-2">
        <TextField
          className="w-80"
          label="Vorname"
          type="text"
          value={firstname}
          onChange={(event) => setFirstname(event.target.value)}
        />
        <TextField
          className="w-80"
          label="Nachname"
          type="text"
          value={lastname}
          onChange={(event) => setLastname(event.target.value)}
        />
        <TextField
          className="w-80"
          label="Email"
          type="email"
          value={email}
          onChange={(event) => setEmail(event.target.value)}
        />
        <TextField
          className="w-80"
          label="Telefonnummer"
          type="tel"
          value={phonenr}
          onChange={(event) => setPhonenr(event.target.value)}
        />
        <TextField
          className="w-80"
          label="PLZ"
          type="number"
          value={postcode}
          onChange={(event) => setPostcode(event.target.value)}
        />
        <TextField
          className="w-80"
          label="Stadt"
          type="text"
          value={city}
          onChange={(event) => setCity(event.target.value)}
        />
        <TextField
          className="w-80"
          label="Straße und Hausnummer"
          type="text"
          value={street}
          onChange={(event) => setStreet(event.target.value)}
        />
        <TextField
          className="w-80"
          select
          label="Anzahl Beuten (max 6)"
          type="number"
          defaultValue="1"
          onChange={(event) => setAmountHives(parseInt(event.target.value))}
        >
                <MenuItem value={1}>1</MenuItem>
                <MenuItem value={2}>2</MenuItem>
                <MenuItem value={3}>3</MenuItem>
                <MenuItem value={4}>4</MenuItem>
                <MenuItem value={5}>5</MenuItem>
                <MenuItem value={6}>6</MenuItem>
        </TextField>

         <TextField
          className="w-80"
          label="TSK-Nr (Tierseuchenkasse)"
          helperText="kann nachgereicht werden"
          type="text"
          value={tsknr}
          onChange={(event) => setTsknr(event.target.value)}
        />
        <Button onClick={() => varroatestStore.registerVarroaTest({ firstname, lastname, email, phonenr, postcode, city, street, amounthives, tsknr}) } variant="contained" className="w-80 p-100"> 
          Beprobungsanfrage Stellen
        </Button>
        <span className="w-80">
        {ErrorReport}</span>
        </div>
        </Box>
       
      </div>
      </div>
        
  );
};

// onClick={() => authStore.login({ email, email })}

const RegisterVarroaTest = observer(RegisterVarroaTestView);
export { RegisterVarroaTest };
/*
<TextField
className="text-field"
label="Anzahl Beuten"

helperText="maximal 6"
type="number"

value={amounthives}
onChange={(event) => setAmountHives(event.target.value)}
/>*/