import { Routes as ReactRouterRoutes, Route } from "react-router-dom";
import { LoginPage } from "../pages/login.page";
import { SignupPage } from "../pages/signup.page";
import { MonitoringRegistrationPage } from "../pages/monitoring.page";

const Routes: React.FC = () => {
    return <ReactRouterRoutes>
        <Route path="login" element={<LoginPage />} />
        <Route path="signup" element={<SignupPage />} />
        <Route path="monitoring" element={<MonitoringRegistrationPage />} />
    </ReactRouterRoutes>;
};

export {Routes};