import { RegisterVarroaTestRequest } from "../dto/request/registervarroatest-request";

import { API_URL } from "../utils/url";

export class VarroaTestService {
  async registerVarroaTest(registerVarroaTestRequest: RegisterVarroaTestRequest) {
    const response = await fetch(`${API_URL}/varroatest/register`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(registerVarroaTestRequest),
    });

    const parsedResponse = await response.json();

    if (!response.ok) {
      throw new Error(parsedResponse);
    }

    return parsedResponse;
  }
}
